export default function Nav() {
  return (
    <>
      <header className="p-4 dark:bg-zinc-900 dark:text-zinc-100">
        <div className="container flex justify-between h-16 mx-auto md:justify-center md:space-x-8">
          <ul className="items-stretch hidden space-x-3 md:flex">
            <li className="flex">
              <a
                rel="noopener noreferrer"
                href="#about"
                className="flex items-center px-4 -mb-1 border-b-2 dark:border-transparent dark:border-zinc-400"
              >
                About
              </a>
            </li>
          </ul>
          <a
            rel="noopener noreferrer"
            href="#home"
            aria-label="Back to homepage"
            className="flex items-center p-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              fill="currentColor"
              className="w-10 h-10 dark:text-amber-400"
            >
              <path d="M196.2 34.57c-7.5 16.07-17.3 39.33-25.7 62.86-4 11.17-7.6 22.47-10.5 32.77 80.8 26 111.2 26 192 0-2.9-10.3-6.5-21.6-10.5-32.77-8.4-23.53-18.2-46.79-25.7-62.86-26.5 13.22-42.6 20.86-59.8 20.86-17.2 0-33.3-7.64-59.8-20.86zM32.28 139H27.34c-1.71 0-2.8.1-3.72.3l-.41.5c-5.12 6.8-6.92 12.6-6.92 17.8 0 5.1 1.9 10.3 6.14 15.9 8.48 11.1 26.73 22.9 50.92 32.5C121.7 225.4 193.1 237.4 256 237.4c62.9 0 134.3-12 182.7-31.4 24.2-9.6 42.4-21.4 50.9-32.5 4.2-5.6 6.1-10.8 6.1-15.9 0-5.2-1.8-11-6.9-17.8l-.4-.5c-2-.3-6-.5-11.4-.2-11.8.7-29.8 3.2-51.8 6.2-14.8 2-31.5 4.3-49.5 6.4.8 5.3 1.3 10.2 1.3 14.7v4.8l-4 2.7c-27.1 18-71.9 25.5-117 25.5-45.1 0-89.9-7.5-117-25.5l-4-2.7v-4.8c0-4.5.5-9.4 1.3-14.7-18-2.1-34.7-4.4-49.51-6.4-22.01-3-40-5.5-51.82-6.2-.93-.1-1.83-.2-2.69-.1zm123.32 8.6c-1.1 5.2-1.9 9.8-2.3 13.7 22.1 12.5 62.4 20.1 102.7 20.1 40.3 0 80.6-7.6 102.7-20.1-.4-3.9-1.2-8.5-2.3-13.7-81.3 25.9-119.5 25.9-200.8 0zM137 265.4c.2 14 1.9 27.4 4.9 40.2 14.5-.4 29.9-3.9 44.9-9.6 8.2-3.2 16.2-7 23.9-11.2-6.1 1-12.4 1.6-18.7 1.6-14.7 0-36.1-10.1-55-21zm238 0c-18.9 10.9-40.3 21-55 21-6.3 0-12.6-.6-18.7-1.6 7.7 4.2 15.7 8 23.9 11.2 15 5.7 30.3 9.2 44.9 9.6 3-12.8 4.7-26.2 4.9-40.2zm-112.1 15.3c1.7 24.1 20.9 41.5 45 58.5-36.2-5.4-59.3-20.8-68.5-51.2-13.7 9.7-29.5 18.3-46.2 24.8-19.2 7.4-39.7 11.7-59.6 10.6 8.3 10.7 15.7 20.5 22.4 29.7 25.2 19.2 45.7 36.8 102.7 45.4-24 3.7-51.2 6.2-72.5-.9 16 25.8 28.2 65.6 43.3 95.8h53c7.9-15.9 15-45.9 22.3-59.4-16.6 13.6-34.5 16.6-47.9 15.2 34.1-14.6 53.4-37.4 74-59.6 12.4-19.3 27.3-40.3 47.5-66.2-19.9 1.1-40.4-3.2-59.6-10.6-20.9-8.1-40.3-19.6-55.9-32.1zm219 18c-5.5 0-11.7.6-18.6 1.7-15.7 2.6-34.6 7.7-55.3 14.3l-1 1.3c-12.2 15.3-22.7 28.8-31.8 41.1 15.4 9.5 24.9 20.3 35 30.9-14.5-5.7-27.4-11.7-45.4-16.6-5.1 7.2-9.8 14-14.1 20.5 9.6 3.7 19.9 6.7 30.8 9.3 26.9 6.5 56 10.1 81.5 16.6-18-27.2-43.4-55.2-74.3-77 20.1-4.1 40.6-8.6 58.7-14.9 19.9-6.9 35.9-15.8 45.2-26.5-1.8-.2-3.4-.5-5.4-.6-1.7-.1-3.4-.1-5.3-.1z"></path>
            </svg>
          </a>
          <ul className="items-stretch hidden space-x-3 md:flex">
            <li className="flex">
              <a
                rel="noopener noreferrer"
                href="#projects"
                className="flex items-center px-4 -mb-1 border-b-2 dark:border-zinc-400"
              >
                Projects
              </a>
            </li>
          </ul>
          <button title="Button" type="button" className="p-4 md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 dark:text-gray-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </header>
    </>
  );
}
